import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import Blogs from '../components/Blogs/Blogs'
import GAMBLING_TAGS from '../gambling-tags'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import { GAMBLING } from '../components/internal-links'
import { LATEST_RC as GAMBLING_DOWNLOAD_LINK } from '../components/download-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Gambling',
    url: GAMBLING,
  },
]

const LearnGambling: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={GAMBLING_DOWNLOAD_LINK}
    >
      <SEO
        title="Gambling: Its types, strategies, methods, and gameplay | Mega"
        description="Get to know the legal status of betting in India along with the various types and their gameplays. We have listed 15+ articles for you. Read now."
        organizationSchema={generateOrgSchema()}
        websiteSchema={generateWebsiteSchema()}
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="gambling"
        tags={GAMBLING_TAGS}
        prefix={`/${process.env.GATSBY_CMS_GAMBLING_PREFIX}` || '/'}
        title={
          <>
            Gambling - <span className="underline">Guide</span>
          </>
        }
      />
    </Layout>
  )
}

export default LearnGambling
